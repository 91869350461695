import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const steps = ["Email Address", "Email Provider"];

function CustomStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  return (
    <Box
      sx={{
        color: completed ? "#ffcf33" : active ? "#ffcf33" : "grey",
      }}
    >
      {completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
    </Box>
  );
}

export default function Steppers({ step }) {
  return (
    <Box sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                ".MuiStepLabel-label": {
                  // Targeting the text label
                  color: "secondary.main", // Default text color
                  "&.Mui-active": {
                    color: "#ffcf33", // Active text color
                  },
                  "&.Mui-completed": {
                    color: "#ffcf33", // Completed text color
                  },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
